import React, { useState, useEffect } from 'react';
import common from "./pics/logo_top.png";
import SidebarMenu from "./Components_2/sideBarMenu";
import CarouselWithMap from './Components_2/CarouselWithMap';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Reference() {
    const [t, i18n] = useTranslation("global");

    const [reference, setReference] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL + `/api/kpbi-references?populate=*&sort[0]=id:desc&locale=${i18n.language}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setReference(data.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [i18n.language]);

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;

    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap />
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>{t("reference")}</h3>
                            <hr className="green-line" />
                        </div>
                        {reference.map(ref => (
                            <div key={ref.id} className="col-md-6 mb-4">
                                <Link to={`/reference-detail/${ref.id}`} className="odkaz_projekt">
                                    <div className="card h-100">
                                        <img className="card-img-top" src={ref.attributes?.coverImage?.data ? process.env.REACT_APP_API_URL + ref.attributes?.coverImage?.data?.attributes.url : common} alt={ref.attributes.name} />
                                        <hr className="card_hr" />
                                        <div className="card-body">
                                            <p className="card-title projekt_odkaz">{ref.attributes.name}</p>
                                            <p className="vice">{t("vice")}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                <SidebarMenu />
            </div>
        </div>
    );
}

export default Reference;
