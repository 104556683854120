import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import common from "./pics/logo_top.png";
import SidebarMenu from './Components_2/sideBarMenu';
import CarouselWithMap from "./Components_2/CarouselWithMap";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';

function Seminare() {
    const [t, i18n] = useTranslation("global");
    const [seminare, setSeminare] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, pageCount: 0, pageSize: 20, total: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Function to fetch data
    const fetchData = useCallback((pageNumber, pageSize, language) => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL + `/api/kpbi-seminares?populate=*&pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&locale=${language}&sort[0]=id:desc`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setSeminare(data.data);
                setPagination(data.meta.pagination);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(pagination.page, pagination.pageSize, i18n.language);
    }, [pagination.page, pagination.pageSize, i18n.language, fetchData]);

    // Handle page change
    const handlePageChange = (newPage) => {
        setPagination(prevState => ({ ...prevState, page: newPage }));
    };

    if (loading) return <div>Načítání...</div>;
    if (error) return <div>Chyba: {error.message}</div>;
    return (
        <div>
            <div className="carousel_ostatni">
                <CarouselWithMap/>
            </div>
            <div className='row'>
                <div className='col-lg-9'>
                    <div className="col-lg-12">
                        <div className="nadpis_padding">
                            <h3>{t("seminare_konference_v")}</h3>
                            <hr className="green-line"/>
                        </div>
                        <div className='row'>
                            {seminare.map(seminar => (
                                <div key={seminar.id} className="col-md-6 mb-4">
                                    <Link to={`/seminare-konference-detail/${seminar.id}`} className="odkaz_projekt">
                                        <div className="card h-100">
                                            <img className="card-img-top" src={seminar.attributes?.coverImage?.data && seminar.attributes.coverImage.data.length > 0 ? process.env.REACT_APP_API_URL + seminar.attributes?.coverImage?.data[0].attributes.url : common} alt={seminar.attributes.name}/>
                                            <hr className="card_hr"/>
                                            <div className="card-body">
                                                <p className="card-title projekt_odkaz">{seminar.attributes.name}</p>
                                                <p className="vice">{t("vice")}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {pagination.pageCount > 1 && (
                            <div>
                                <ul className='pagination'>
                                    {[...Array(pagination.pageCount)].map((_, index) => (
                                        <li
                                            key={index}
                                            className={`page-item  ${pagination.page === index + 1 ? 'active' : ''}`}
                                        >
                                            <button
                                                className='page-link'
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <button className='btn btn-primary btn-back btn_vice' onClick={() => navigate(-1)}>
                        {t("zpet")}
                    </button>
                </div>
                <SidebarMenu/>
            </div>
        </div>
    );
}

export default Seminare;
